.react-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
  min-width: 300px;
}

.react-tagsinput-true {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
  min-width: 300px;
}

.react-tagsinput-false {
  background-color: #fff;
  border: 1px solid rgb(252, 0, 0);
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
  min-width: 300px;
}

.react-tagsinput--focused {
  /* border-color: #a5d24a; */
}

.react-tagsinput-tag {
  background-color: #50a5f1;
  border-radius: 2px;
  /* border: 1px solid #556ee6; */
  color: #fff;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: " ×";
  color: #fff;
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  /* font-family: sans-serif; */
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 80px;
  min-width: 300px;
}
